<template>
            <div>
                <div class="row">
                    <ul v-for="k in unavailable" :key="k" class="col">
                        <img :src="keeperImage(k.KeeperId)"/>
                        <li>{{k.KeeperName}}</li>
                    </ul>
                </div>
            </div>
</template>

<script>
export default {
    data() {
        return {
            unavailable: []             
        }
    },
    created() {
        this.axios
            .get('https://fantasy-football-backend.vercel.app/api/unavailable')
            .then(response => (this.unavailable = response.data))
    },
    methods: {
        keeperImage(keeperId){
            return "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/"+keeperId+".png&w=210&h=154"
        },
    }
}


</script>