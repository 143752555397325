<template>
<h2>Transactions</h2>

<div  class="transaction-container">
    <!--<div class="autocomplete row-fluid" >
        <input v-model="search" type="search" placeholder="Find a Player..."/>
    </div>-->
    <div class="transaction-container-value">
        
        <div v-for="dates in groupedTransactions" :key="dates.rowKey">
            <div v-for="(data, index) in sortBid(dates)" :key="index" > 
                <div v-if="index === 0" class="processDate">{{data.ProcessDate}}</div>
                <div v-if="data.Status == 'EXECUTED'" >
                    <strong>{{getTeamName(data.TeamId)}} Added {{data.PlayerAddedName}} for ${{data.BidAmount}} </strong><span class="dropped"> | Dropped {{data.PlayerDroppedName}} </span>
                </div>
                <div v-else >
                    <div >
                    {{getTeamName(data.TeamId)}} failed to add {{data.PlayerAddedName}}. Bid ${{data.BidAmount}}
                    </div>
                </div>
                
            </div>
            <hr />
        </div>

    </div>
</div>
    
</template>


<script>
export default {
    data() {
        return {
            search: '',
            transactions: [],
            groupedTransactions: [],
            teamInformation: []
        }
    },
    mounted() {
        this.getData()
    },
    
    methods: {
        getData(){
            this.axios
            .get('https://fantasy-football-backend.vercel.app/api/teaminfo')
            .then(response => (this.teamInformation = response.data)),

            this.axios
            .get('https://fantasy-football-backend.vercel.app/api/transactions')
            .then(response => {
                this.manipulateData(response.data)
            })    
        },
        manipulateData(data) {
            this.transactions = data

            var tempTransactions = this.transactions
            this.batchTransactions(data)
            //return tempTransactions
            return tempTransactions.reduce((result, currentValue) => {
                (result[currentValue.ProcessDate] = result[currentValue.ProcessDate] || []).push(currentValue);
                    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                    return result;
                }, {});
            
        },

        batchTransactions(data){
            var groupedData = {}
            
            data.forEach(item => {
                
                if (!groupedData[item.ProcessDate]) {
                    groupedData[item.ProcessDate] = [];
                }
                groupedData[item.ProcessDate].push(item);
                
            })
            
            return this.groupedTransactions = groupedData
            
        },
        getTeamName(teamID){

            var tempTeamInfo = this.teamInformation
            
            var teamName =  tempTeamInfo.filter((item) => (
                item.PartitionKey == teamID
            ))
            try {
                return teamName[0].TeamOwner
            } catch (error) {
                return ""
            }
            
        },
        sortBid(datesDate){
            
            return datesDate.sort((a, b) => {
                if (a.PlayerAddedName === b.PlayerAddedName){
                    if (a.BidAmount == b.BidAmount){
                        return a.Status.localeCompare(b.Status)
                    }
                    return b.BidAmount - a.BidAmount
                }
                return a.PlayerAddedName > b.PlayerAddedName ? 1 : -1
            })
            
        }
    },

    computed: {
        /*filterData(){
            return this.groupedTransactions
                .filter(({PlayerAddedName, PlayerDroppedName, ProcessDate}) => [PlayerAddedName, PlayerDroppedName, ProcessDate]
                .some(val => val.toLowerCase().includes(this.search.toLowerCase())))
        },*/
        
    }
}
</script>
<style>
.transaction-container{
    margin-left: auto;
    margin-right: auto;
    height: 500px;
    width: 50%;
    margin-bottom: 75px;
}
.transaction-container-value{
    height: 499px;
    overflow: auto;
}
.processDate{
    opacity: 60%;
    float: left;
}
@media only screen and (max-width: 775px) {
.processDate{
    opacity: 60%;
    float: none;
}
.dropped{
    display: none;
}
.transaction-container-value{
    font-size: 3.1vw;
}
.transaction-container{
    width: 95%;
  }
}
</style>