<template>
  <h2>Keepers</h2>
  <div class="container">
    <div class="nav nav-tabs">
      <!--<div class="nav-item" @click="activeComponent = 'Keeper1'" id="FirstTab">
                <a class="nav-link" :class=" activeComponent === 'Keeper1' ? 'active' : '' ">2020</a>
            </div>-->
      <div class="nav-item" @click="activeComponent = 'Keeper2'">
        <a
          class="nav-link"
          :class="activeComponent === 'Keeper2' ? 'active' : ''"
          >2023</a
        >
      </div>
      <div class="nav-item" @click="activeComponent = 'Keeper3'">
        <a
          class="nav-link"
          :class="activeComponent === 'Keeper3' ? 'active' : ''"
          >2024</a
        >
      </div>
      <div class="nav-item" @click="activeComponent = 'Available'">
        <a
          class="nav-link"
          :class="activeComponent === 'Available' ? 'active' : ''"
          >Available for 2025</a
        >
      </div>
      <div class="nav-item ml-auto" id="expandAll">
        <a
          class="nav-link"
          data-toggle="collapse"
          @click="toggle"
          data-target=".multi-target"
          aria-expanded="true"
          aria-controls="collapseOne collapseTwo"
          >{{ expandCollapse }} All</a
        >
      </div>
    </div>
    <Transition name="fade" mode="out-in">
      <div>
        <component :is="activeComponent"></component>
      </div>
    </Transition>
  </div>
</template>

<script>
import Keeper1 from "./Keeper Components/KeeperYear1.vue";
import Keeper2 from "./Keeper Components/KeeperYear2.vue";
import Keeper3 from "./Keeper Components/KeeperYear3.vue";
import Available from "./Keeper Components/Available.vue";

// import {shallowRef} from 'vue'

export default {
  components: { Keeper1, Keeper2, Keeper3, Available },
  data() {
    return {
      activeComponent: "Keeper2",
      expandCollapse: "Expand",
    };
  },
  methods: {
    toggle() {
      if (this.expandCollapse == "Expand") {
        this.expandCollapse = "Collapse";
      } else {
        this.expandCollapse = "Expand";
      }
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.container {
  max-width: 50%;
  padding-bottom: 25px;
}
.nav-item {
  cursor: pointer;
}
@media only screen and (max-width: 875px) {
  #expandAll {
    display: none;
  }
}
</style>
