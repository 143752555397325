<template>
  <h2>Check Roster Values</h2>
  <div class="container">
    <select @change="getPlayers($event)">
      <option value="none">Select Team</option>
      <option
        v-for="teams in teamInformation"
        :key="teams.rowKey"
        :value="teams.PartitionKey"
      >
        {{ teams.TeamName }}
      </option>
    </select>
  </div>
  <div class="container">
    <div class="autocomplete row-fluid">
      <input v-model="search" type="search" placeholder="Find a Player..." />
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Cost to Keep</th>
          <th scope="col">ESPN Bid Price</th>
          <th scope="col">Average Bid Value</th>
          <th scope="col">7 Day Change</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="player in filterData" :key="player.RowKey">
          <td>{{ player.PlayerName }}</td>
          <td :class="{ strikeThrough: isUnavailable(player.RowKey) }">
            ${{ addFive(player.PlayerValue) }}
          </td>
          <td>${{ player.DraftValue }}</td>
          <td>${{ round(player.AverageAuctionValue) }}</td>

          <td v-if="round(player.AuctionValueChange) < -0" class="RedAverage">
            ${{ round(player.AuctionValueChange) }}
          </td>
          <td
            v-else-if="round(player.AuctionValueChange) > 0"
            class="GreenAverage"
          >
            ${{ round(player.AuctionValueChange) }}
          </td>
          <td v-else>${{ round(player.AuctionValueChange) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      teamInformation: [],
      rosters: [],
      search: "",
      unavailable: [
        "4239996",
        "4362628",
        "2977187",
        "3126486",
        "4240021",
        "4372016",
      ],
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.axios
        .get("https://fantasy-football-backend.vercel.app/api/teaminfo")
        .then((response) => (this.teamInformation = response.data));

      this.axios
        .get("https://fantasy-football-backend.vercel.app/api/roster")
        .then((response) => (this.rosters = response.data));
    },
    getPlayers(teamId) {
      console.log(teamId.target.value);
      this.axios
        .get(
          "https://fantasy-football-backend.vercel.app/api/roster?id=" +
            teamId.target.value
        )
        .then((response) => (this.rosters = response.data));
      //console.log(this.rosters.filter(player => player.PartitionKey === teamId.target.value))
    },
    addFive(lastYearValue) {
      return parseFloat(lastYearValue) + 5;
    },
    round(averagePrice) {
      return parseFloat(averagePrice).toFixed(2);
    },
    isUnavailable(rowKey) {
      return this.unavailable.includes(rowKey);
    },
  },
  computed: {
    filterData() {
      return this.rosters.filter(({ PlayerName }) =>
        [PlayerName].some((val) =>
          val.toLowerCase().includes(this.search.toLowerCase())
        )
      );
    },
  },
};
</script>

<style>
.GreenAverage {
  color: green;
  font-size: large;
}
.RedAverage {
  color: red;
  font-size: large;
}
.table {
  table-layout: fixed;
}
.strikeThrough {
  text-decoration: line-through;
}
</style>
