<template>
  <h2>Weekly Points Winners</h2>
  <div class="container">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Week</th>
          <th scope="col">Team</th>
          <th scope="col">Highest Scoring Player</th>
          <th scope="col">Points Scored</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="weekWinner in winnerInformation" :key="weekWinner.RowKey">
          <td>{{ weekWinner.Week }}</td>
          <td>{{ updateTeamName(weekWinner.PartitionKey) }}</td>
          <td>{{ weekWinner.HighestScoringPlayerName }}</td>
          <td>{{ weekWinner.PointsScored }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <hr />
  <h2>Power Ranking</h2>
  <div class="container">
    <Dropdown
      v-model="selectedWeek"
      :options="powerRankWeek"
      optionLabel="week"
      optionValue="value"
      @change="change"
      placeholder="Select a Week"
      class="w-full md:w-14rem"
    />
    <DataTable :value="powerRanking">
      <Column field="TeamName" header="Team Name"></Column>
      <Column field="PowerRank" header="Ranking"></Column>
    </DataTable>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
export default {
  data() {
    return {
      winnerInformation: [],
      teaminfo: [],
      powerRankWeek: [
        { week: "Week 1", value: 1 },
        { week: "Week 2", value: 2 },
        { week: "Week 3", value: 3 },
        { week: "Week 4", value: 4 },
        { week: "Week 5", value: 5 },
        { week: "Week 6", value: 6 },
        { week: "Week 7", value: 7 },
        { week: "Week 8", value: 8 },
        { week: "Week 9", value: 9 },
        { week: "Week 10", value: 10 },
        { week: "Week 11", value: 11 },
        { week: "Week 12", value: 12 },
        { week: "Week 13", value: 13 },
      ],
      selectedWeek: [],
      powerRanking: [],
    };
  },
  mounted() {
    this.getData();
    this.getPowerRanking();
  },

  methods: {
    getData() {
      this.axios
        .get("https://fantasy-football-backend.vercel.app/api/winners")
        .then((response) => (this.winnerInformation = response.data));

      this.axios
        .get("https://fantasy-football-backend.vercel.app/api/teaminfo")
        .then((res) => (this.teaminfo = res.data));
    },
    getPowerRanking() {
      this.axios
        .get(
          "https://fantasy-football-backend.vercel.app/api/powerranking?week=1"
        )
        .then((response) => (this.powerRanking = response.data));
    },
    change(event) {
      this.powerRanking = [];
      this.axios
        .get(
          "https://fantasy-football-backend.vercel.app/api/powerranking?week=" +
            event.value
        )
        .then((response) => (this.powerRanking = response.data));
    },
    updateTeamName(teamID) {
      var teams = this.teaminfo;

      const getTeamName = teams.filter(function (team) {
        if (teamID == team.PartitionKey) return team.TeamName;
      });
      return getTeamName[0].TeamName;
    },
  },
  components: {
    Dropdown,
    DataTable,
    Column,
  },
};
</script>
