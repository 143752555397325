<template>
  <div v-if="isAuthenticated">
    <h2>{{user.name}}'s Current Picks</h2>
  </div>
  <div v-if="isAuthenticated">
      <TransitionGroup name="fade" tag="div" class="row">
        <template  v-for="(bets, index) in PlacedBets" :key="bets.RowKey">
          <div  class="col-sm-4 justify-content-center align-items-stretch" v-if="bets.BetVisible">
            <div class="card shadow item" :key="bets.RowKey">
              <div class="card-body">
                <button type="button" class="close" aria-label="Close" v-if="bets.BetWon != ''" @click="remove(index)">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="card-title">{{bets.AwayTeamName}} @ <br>{{bets.HomeTeamName}}</h5>
                <h3 class="card-text">
                  {{this.plusOrMinus(bets.BetPlaced)}}
                </h3>
                <div class="card-text">
                  <div class="wagered">Wagered ${{bets.Amount}}</div>
                  <div class="toWin" v-if="bets.BetWon.toUpperCase() != 'PUSH'">{{changeWording(bets.BetWon)}} <span :class="styleWinnings(bets.BetWon)"><strong>${{bets.AmountToWin}}</strong></span></div>
                  <div class="toWin" v-else>{{changeWording(bets.BetWon)}} <span :class="styleWinnings(bets.BetWon)"><strong>${{bets.Amount}}</strong></span></div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </TransitionGroup>
      <hr/>
      <h3>Your Previous Bets</h3>
      <TransitionGroup name="fade" tag="div" class="row">
        <template  v-for="(bets) in PlacedBets" :key="bets.RowKey">
          <div  class="col-sm-4 justify-content-center align-items-stretch" v-if="!bets.BetVisible">
            <div class="card shadow item" :key="bets.RowKey">
              <div class="card-body">
                <!-- <button type="button" class="close" aria-label="Close" v-if="bets.BetWon != ''" @click="remove(index)">
                  <span aria-hidden="true">&times;</span>
                </button> -->
                <h5 class="card-title">{{bets.AwayTeamName}} @ <br>{{bets.HomeTeamName}}</h5>
                <h3 class="card-text">
                  {{this.plusOrMinus(bets.BetPlaced)}}
                </h3>
                <div class="card-text">
                  <div class="wagered">Wagered ${{bets.Amount}}</div>
                  <div class="toWin" v-if="bets.BetWon.toUpperCase() != 'PUSH'">{{changeWording(bets.BetWon)}} <span :class="styleWinnings(bets.BetWon)"><strong>${{bets.AmountToWin}}</strong></span></div>
                  <div class="toWin" v-else>{{changeWording(bets.BetWon)}} <span :class="styleWinnings(bets.BetWon)"><strong>${{bets.Amount}}</strong></span></div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </TransitionGroup>
  </div>

</template>

<script>

import { useAuth0 } from '@auth0/auth0-vue';

export default {
  data() {
    const { isAuthenticated, user } = useAuth0();
    return {
      PlacedBets: [],
      isAuthenticated,
      user,
    };
  },
  mounted() {
    this.getData();
    this.checkIfGamesEnded();
  },

  methods: {
    getData() {
      this.axios
        .get(
          "https://fantasy-football-bets.vercel.app/api/getBet?id="+ String(this.user.sub) + ""
        )
        .then((response) => (this.PlacedBets = response.data));
    },
    checkIfGamesEnded(){
      this.axios
        .get(
          "https://ffazurefunctions.azurewebsites.net/api/FFUpdateSlips?name="+ String(this.user.name) + ""
        )
    },
    plusOrMinus(amount){

      var team = amount.split(" ")[0]
      var spread = amount.split(" ")

      return spread.length > 2 ? team + " +" + spread[2] : team + " "+spread[1]
    },
    styleWinnings(val){
      
      if(val.toUpperCase() =="FALSE"){
        return "Red"
      }else if (val.toUpperCase() == "PUSH"){
        return "Yellow"
      }
      else{
        return "Green"
      }
    },
    changeWording(val){
      
      if(val.toUpperCase() =="FALSE"){
        return "Bet Lost "
      }else if (val.toUpperCase() == "PUSH"){
        return "Bet Push "
      }
      else if (val.toUpperCase() == "TRUE") {
        return "You Won "
      }else{
        return "To Win "
      }
    },
    remove(index){
      console.log(this.PlacedBets[index].RowKey)
      var valuesToPass = {
        rowKey: this.PlacedBets[index].RowKey
      }
      this.axios
           .post("https://fantasy-football-bets.vercel.app/api/hideSlips", valuesToPass)
           .then(this.PlacedBets.splice(index, 1))
    }
  }
};
</script>
<style>
.wagered {
  float: left;
}
.toWin {
  float: right;
}
.Green{
  color: green; 
  font-size: large
}
.Yellow{
  color: grey;
  font-size: large;

}
.Red{
  color: red;
  font-size: large;
  text-decoration: line-through;
}
.hideCard{
  display: none;
}
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}
</style>