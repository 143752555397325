<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="/">The Family or Fantasy</a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarText"
      aria-controls="navbarText"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav mr-auto">
        <!-- <li class="nav-item">
                <a class="nav-link" href="/roster">Roster </a>
            </li> -->
        <li class="nav-item">
          <a class="nav-link" href="/winners">Winners/ Rankings </a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" href="/roster">Roster Draft Value </a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" href="/projections">Projections </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/Streams">Streams </a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link" href="/NFLTotals">NFL Total Team Points </a>
        </li>-->
        <!-- <li class="nav-item" v-if="isAuthenticated">
                <a class="nav-link" href="/pickem">PickEm </a>
            </li> -->
        <li class="nav-item dropdown" v-if="isAuthenticated">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            PickEm
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="/pickem">Games</a>
            <a class="dropdown-item" href="/pickem/slips">Slips</a>
          </div>
        </li>
      </ul>
      <form class="form-inline user-info" v-if="isAuthenticated">
        <span class="navbar-text loggedInUser"
          >{{ user.given_name }} {{ user.name }}</span
        >
        <button
          class="btn btn-outline-secondary my-2 my-sm-0 button"
          type="logOut"
          @click="logout"
        >
          Log Out
        </button>
      </form>
      <form class="form-inline user-info" v-else>
        <button
          class="btn btn-outline-secondary my-2 my-sm-0 button"
          type="logIn"
          @click="login"
        >
          Log In
        </button>
      </form>
    </div>
  </nav>
</template>
<script>
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  setup() {
    const { loginWithRedirect, user, isAuthenticated, logout } = useAuth0();

    return {
      login: () => {
        loginWithRedirect();
      },
      logout: () => {
        logout({ returnTo: window.location.origin });
      },
      user,
      isAuthenticated,
    };
  },
};
</script>

<style>
.navbar {
  padding-left: 10px;
  margin-bottom: 15px;
}
.loggedInUser {
  padding-right: 10px;
}
.button {
  margin-right: 15px;
}
/* .nav-link:hover {
  opacity: 1;
} */
.nav-link::before {
  transition: 300ms;
  height: 5px;
  content: "";
  position: absolute;
  background-color: #031d44;
}

.nav-link-fade-up::before {
  width: 100%;
  bottom: 5px;
  opacity: 0;
}

.nav-link-fade-up:hover::before {
  bottom: 10px;
  opacity: 1;
}
</style>
