<template>
    <Navigation />
    <PickEm />
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'
import PickEm from '../components/PickEm.vue';


export default {
  name: 'Home',
  components: {
    Navigation,
    PickEm
}
}
</script>