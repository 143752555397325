<template>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
* {
  color-scheme: light dark;
}

ul {
  list-style-type: none;
}
@media only screen and (max-width: 806px) {
  .container {
    max-width: 100% !important;
  }
  #FirstTab {
    display: none;
  }
  #expandAll {
    display: block !important;
  }
  .user-info {
    justify-content: center;
  }
  .dropdown-item {
    text-align: center;
  }
}
@media only screen and (max-width: 436px) {
  #expandAll {
    display: none !important;
  }
  .user-info {
    justify-content: center;
  }
  .dropdown-item {
    text-align: center;
  }
}
</style>
