<template>
    <Navigation />
    <WeeklyPoints />
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'
import WeeklyPoints from '@/components/WeeklyPoints.vue'


export default {
  name: 'Home',
  components: {
    Navigation,
    WeeklyPoints
  }
}
</script>