import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { createApp } from "vue";
import { createAuth0 } from "@auth0/auth0-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import router from "./router";
import PrimeVue from "primevue/config";
import "primeflex/primeflex.css"; //flex styling for datatable
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core CSS
import "primeicons/primeicons.css"; //icons
import ToastService from "primevue/toastservice";

createApp(App)
  .use(VueAxios, axios)
  .use(
    createAuth0({
      domain: "dev-v36p32qu0nhlibzp.us.auth0.com",
      clientId: "fJh9PZPCs9ifHcGo0PPaskQDbQbxE5gr",
      redirect_uri: window.location.origin,
    })
  )
  .use(router)
  .use(PrimeVue)
  .use(ToastService)
  .mount("#app");

import "bootstrap/dist/js/bootstrap.js";
