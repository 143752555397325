<template>
  <svg viewBox="0 0 900 490">
    <defs>
      <radialGradient
        id="gradient-1"
        gradientUnits="userSpaceOnUse"
        cx="545"
        cy="213"
        r="500"
        gradientTransform="matrix(0.7, 0, 0, 0.4642, 0, 130)"
      >
        <stop style="stop-color: rgb(19, 19, 19)" offset="0" />
        <!--<stop style="stop-color: rgb(19, 19, 19);" offset="1"/>-->
      </radialGradient>
      <pattern
        id="pattern-2"
        viewBox="-10 13 181 180"
        patternUnits="userSpaceOnUse"
        width="100"
        height="100"
      >
        <!--<rect x="86.5" y="71.3" width="6.9" height="180.6" style="fill: rgb(216, 216, 216);" transform="matrix(1, 0, 0, 1, -9.2, -57.9)"/>-->
        <rect
          x="86.5"
          y="71.3"
          width="6.9"
          height="180.6"
          style="fill: rgb(216, 216, 216); stroke-width: 1"
          transform="matrix(0, 1, -1, 0, 242, 13)"
        />
      </pattern>
      <pattern
        id="pattern-3"
        patternTransform="matrix(0.22, 0, 0, 0.22513, 180, 317)"
        xlink:href="#pattern-2"
      />
    </defs>

    <rect width="100%" height="490" style="fill: url(#gradient-1)" />
    <rect
      x="79.6"
      y="59.6"
      width="750"
      height="360"
      style="
        fill: url(#pattern-3);
        fill-opacity: 0.2;
        stroke: rgb(105, 105, 104);
      "
    />

    <text
      x="40%"
      y="36.8"
      style="
        font-size: 16px;
        font-family: Roboto;
        fill: rgb(251, 251, 251);
        word-spacing: 0px;
      "
    >
      Projection vs Actual Points Scored
    </text>
    <text
      x="49%"
      y="475"
      style="
        font-size: 16px;
        font-family: Roboto;
        fill: rgb(251, 251, 251);
        word-spacing: 0px;
      "
    >
      NFL Weeks
    </text>

    <template v-for="player in totalTeamValues" :key="player.Name">
      <circle
        :cx="plotX(player.week)"
        :cy="plotProjectedY(player.projected)"
        :r="5"
        fill="red"
        stroke="white"
        stroke-dasharray="4"
        opacity="0.3"
      />
      <circle
        :cx="plotX(player.week)"
        :cy="plotActualY(player.actual)"
        :r="5"
        fill="red"
        :class="player.NFLTeam"
      />
    </template>

    <g class="y-axis">
      <template v-for="(score, index) in yPlotValues" :key="score.Name">
        <text :y="getYAxisValue(index)" x="40">{{ score }}</text>
      </template>
    </g>
    <g class="x-axis" transform="matrix(1, 0, 0, 1, 32, 12)">
      <!--<text y="430" x="40">Week 1</text>-->
      <template v-for="index in 18" :key="index">
        <text y="430" :x="42 * index + 20">{{ index }}</text>
      </template>
    </g>
  </svg>
</template>

<script>
export default {
  props: ["passTeam", "passPosition", "passSwitchView"],
  data() {
    return {
      //projected: [{"Name":"Damien Harris", "Projected" :10.4}],
      //actual: [{"Name":"Damien Harris", "Actual" :21}]
      playerDataValue: [],
      totalTeamValues: [],
      yPlotValues: [],
    };
  },
  watch: {
    passTeam() {
      //console.log(this.passPosition)
      if (this.passPosition !== "") {
        this.getValues();
      }
    },
    passPosition() {
      //console.log(this.passPosition)
      if (this.passTeam !== undefined) {
        this.getValues();
      }
    },
    passSwitchView() {
      this.getValues();
    },
  },
  methods: {
    plotX(week) {
      var index = parseInt(week);
      const xCord = 52 + parseInt(index) * 42;
      return xCord;
    },
    plotProjectedY(points) {
      console.log("Projected");
      console.log(points);
      var wholeNumber = Math.round(points);
      var plottedYProj = this.yPlotValues;
      console.log("plottedYProj " + plottedYProj);
      var scale =
        plottedYProj[1] - plottedYProj[2] == 0
          ? 2
          : plottedYProj[1] - plottedYProj[2];
      console.log("scale " + scale);
      var topCheck = plottedYProj[0] - plottedYProj[1];
      if (points > plottedYProj[1] && topCheck != scale) {
        return 60;
      } else {
        var calculation = Math.round((45 / scale) * wholeNumber);
        console.log("calculation " + calculation);
        var bottomY = this.yPlotValues[8];
        var adjustment = (45 / scale) * bottomY;
        console.log("bottomY " + bottomY + " adjustment " + adjustment);
        var place = 420 - calculation + adjustment;
        console.log(place);

        return place;
      }
    },
    plotActualY(points) {
      var wholeNumber = Math.round(points);
      //console.log(this.totalTeamValues)
      var plottedYAct = this.yPlotValues;
      var scale =
        plottedYAct[1] - plottedYAct[2] == 0
          ? 2
          : plottedYAct[1] - plottedYAct[2];
      var topCheck = plottedYAct[0] - plottedYAct[1];
      //console.log('points ' + points + ' actual plottedY ' + plottedYAct)
      if (points > plottedYAct[1] && topCheck != scale) {
        //console.log(points + ' inside top ' + plottedYAct[1])
        return 60;
      } else {
        var calculation = Math.round((45 / scale) * wholeNumber);
        //console.log('calculation ' + calculation)
        var bottomY = this.yPlotValues[8];
        var adjustment = (45 / scale) * bottomY;
        //console.log('bottomY ' + bottomY + ' adjustment ' + adjustment)
        var place = 420 - calculation + adjustment;
        //console.log(place)
        //console.log(place)
        return place;
      }
    },
    getValues() {
      //console.log('in get values '+teamName)
      return this.axios
        .get(
          "https://fantasy-football-backend-nfl-data.vercel.app/api/nflPlayerData?" +
            this.passSwitchView +
            "=" +
            this.passTeam +
            "&position=" +
            this.passPosition
        )
        .then((response) => {
          this.groupTeamValues(response.data);
        });
    },
    groupTeamValues(playerData) {
      this.playerDataValue = playerData;
      this.$emit("dataValues", this.playerDataValue);
      this.$emit("positionSelect", this.passPosition);
      var tempDataValue = playerData;
      this.totalTeamValues = [];
      var uniqueTeam = tempDataValue
        .map((x) => x.PartitionKey)
        .filter((value, index, self) => self.indexOf(value) == index);
      //console.log('get unique team value' + uniqueTeam)
      uniqueTeam.forEach((value, index) => {
        var playersByTeam = tempDataValue.filter(
          (t) => t.PartitionKey == value
        );
        console.log("players by team " + JSON.stringify(playersByTeam));
        var total = playersByTeam.reduce(
          (prev, item) => parseInt(prev) + parseInt(item.Actual),
          0
        );
        var totalP = playersByTeam.reduce(
          (prev, item) =>
            Math.round(parseFloat(prev) + parseFloat(item.Projected)),
          0
        );

        this.totalTeamValues.push({
          team: value,
          week: playersByTeam[0].PartitionKey,
          projected: totalP,
          actual: total,
        });
      });
      //console.log(this.totalTeamValues)
      this.calculateAxis(this.totalTeamValues);
    },
    calculateAxis(teamPointValues) {
      var tempArray = [];

      teamPointValues.forEach((value) => {
        tempArray.push(value.projected);
        tempArray.push(value.actual);
      });
      var max = Math.max.apply(0, tempArray);
      var min = Math.min.apply(0, tempArray);
      console.log("min " + min);
      //console.log('tempArray ' + tempArray + ' and max ' + max)
      tempArray.splice(tempArray.indexOf(max), 1);
      var max2 = Math.max.apply(0, tempArray);
      if (max2 == max) {
        tempArray.splice(tempArray.indexOf(max2), 1);
        var max2 = Math.max.apply(0, tempArray);
      }
      console.log("max " + max);
      console.log("max2 " + max2);

      /*if(((max - min) / 7) < 4){
            this.yPlotValues = []
            var scale = Math.round((max - min) / 7)
            //console.log(scale)
            this.yPlotValues.push(max)
            for(let i = 0; i < 8; i++){
              //console.log(max2-=scale)
              this.yPlotValues.push(max-=scale)
            }
            //this.yPlotValues.reverse();
          }else{*/
      var scale =
        Math.ceil((max2 - min) / 7) == 0 ? 2 : Math.ceil((max2 - min) / 7);
      console.log("scaleInY " + scale);
      this.yPlotValues = [];
      //console.log(scale)
      this.yPlotValues.push(max, max2);
      for (let i = 0; i < 7; i++) {
        //console.log(max2-=scale)
        this.yPlotValues.push((max2 -= scale));
      }
      //this.yPlotValues.reverse();
      console.log("setYAxis " + this.yPlotValues);
      //}
    },
    getYAxisValue(index) {
      return 60 + index * 45;
    },
  },
};
</script>

<style id="bx-google-fonts">
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,400,400italic,500,500italic,700,700italic,900,900italic);

text {
  font-family: sans-serif;
  transition: all 1s ease-in-out;
}

.y-axis text,
.x-axis text {
  text-anchor: middle;
  fill: rgb(103, 102, 102);
  font-size: 12px;
}

/*.label-starwars {
  white-space: pre;
  font-size: 15px;
  fill: rgb(253, 200, 39);
  text-anchor: end;
  word-spacing: 0px;
}

.label-startrek {
  white-space: pre;
  font-size: 15px;
  fill: rgb(33, 125, 245);
  text-anchor: end;
  word-spacing: 0px;
}*/

@media (max-width: 500px) {
  .x-axis text:nth-of-type(2n),
  .y-axis text:nth-of-type(2n) {
    transition: opacity 1s ease-in-out;
    opacity: 0;
  }

  .label-startrek,
  .label-starwars {
    font-size: 170%;
  }
  .y-axis text {
    font-size: 120%;
  }
  .x-axis text {
    font-size: 120%;
  }
}
</style>
