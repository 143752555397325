<template>
  <Navigation />
  <PrivacyPolicy />
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";

export default {
  name: "Home",
  components: {
    Navigation,
    PrivacyPolicy,
  },
};
</script>
