<template>
    <div class="keepersAccordian">
        <div class="accordion" id="accordionExample" v-for="owner in owners" :key="owner.PartitionKey">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h5 class="mb-0">
                        <button class="btn btn-link" type="button" data-toggle="collapse" :data-target="'#team'+owner.PartitionKey" aria-expanded="true" aria-controls="collapseOne">
                            {{ owner.TeamOwner}}
                        </button>
                    </h5>
                </div>
                <div :id="'team'+owner.PartitionKey" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div class="card-body row">
                         <ul v-for="k in teamKeepers(owner.PartitionKey)" :key="k" class="col">
                            <img :src="keeperImage(k.KeeperId)"/>
                            <li>{{k.KeeperName}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            keepers: [],
            owners: [],
        }
    },
    created() {
        this.axios
            .get('https://fantasy-football-backend.vercel.app/api/teaminfo')
            .then(response => (this.owners = response.data))
        this.axios
            .get('https://fantasy-football-backend.vercel.app/api/keepers?year=2020')
            .then(response => (this.keepers = response.data))
    },
    methods: {
        teamKeepers(ownerKey){

            let tempKeeperNames = []
            
            this.keepers.filter((item) => {
                if(item.PartitionKey === ownerKey){
                    tempKeeperNames.push(item)
                }
            })
            return tempKeeperNames
        },
        keeperImage(keeperId){
            return "https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/"+keeperId+".png&w=210&h=154"
        },
    }
}
</script>