<template>
  <h2>Check Player Projections</h2>
  <h4>Projected values are calculated as group totals.</h4>
  <div class="projection-container">
    <div class="row">
      <div class="col-sm-6">
        <div>Select a Team to view their point break down</div>

        <select @change="setTeam($event)">
          <option value="none">Select Team</option>
          <option
            v-for="teams in NFLTeams"
            :key="teams.rowKey"
            :value="teams.DisplayName"
          >
            {{ teams.DisplayName }}
          </option>
        </select>
        <Opponent :passTeam="this.selectedTeam"></Opponent>
        <!--<span>Currently hardcoded for just TE.  Working on the selection part</span><br/>
                <span>Gets player data that is projected >5 or if the player ended with >5</span>-->
      </div>
      <div class="col-sm-6">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio1"
            v-model="selectedPosition"
            value="QB"
          />
          <label class="form-check-label" for="inlineRadio1">QB</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio2"
            v-model="selectedPosition"
            value="RB"
          />
          <label class="form-check-label" for="inlineRadio2">RB</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio3"
            v-model="selectedPosition"
            value="WR"
          />
          <label class="form-check-label" for="inlineRadio3">WR</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio3"
            v-model="selectedPosition"
            value="TE"
          />
          <label class="form-check-label" for="inlineRadio3">TE</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio3"
            v-model="selectedPosition"
            value="K"
          />
          <label class="form-check-label" for="inlineRadio3">K</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio3"
            v-model="selectedPosition"
            value="D"
          />
          <label class="form-check-label" for="inlineRadio3">D/ST</label>
        </div>
        <ProjectionsGraph
          @dataValues="updateDataValue"
          @positionSelect="updatePosition"
          :passTeam="this.selectedTeam"
          :passPosition="this.selectedPosition"
          :passSwitchView="this.switchView"
        ></ProjectionsGraph>
      </div>
    </div>
  </div>
  <div>
    <button
      type="button"
      id="change-team-button"
      class="btn btn-secondary"
      @click="changeView"
    >
      View {{ switchView == "opponent" ? "Team" : "Opponent" }} Data
    </button>
  </div>
  <div>
    <PlayerProfile
      :passDataValues="this.positionData"
      :passPosition="this.position"
      :passTeamOrOpponent="this.switchView"
    ></PlayerProfile>
  </div>
</template>

<script>
import Opponent from "./Projections Components/Opponent.vue";
import ProjectionsGraph from "./Projections Components/ProjectionsGraph.vue";
import PlayerProfile from "./Projections Components/PlayerProfile.vue";

export default {
  data() {
    return {
      NFLTeams: [],
      selectedTeam: undefined,
      selectedPosition: "",
      positionData: null,
      position: null,
      switchView: "opponent",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.axios
        .get(
          "https://fantasy-football-backend-nfl-data.vercel.app/api/nflTeams"
        )
        .then((response) => (this.NFLTeams = response.data));
    },
    setTeam(teamName) {
      this.selectedTeam = teamName.target.value;
      //console.log(this.selectedTeam)
    },
    updateDataValue(val) {
      this.positionData = val;
    },
    updatePosition(val) {
      this.position = val;
    },
    changeView() {
      //console.log(this.switchView)
      if (this.switchView == "team") {
        this.switchView = "opponent";
      } else {
        this.switchView = "team";
      }
    },
  },
  components: { Opponent, ProjectionsGraph, PlayerProfile },
};
</script>
<style>
.projection-container {
  width: 98%;
  padding-left: 10px;
}
#change-team-button {
  margin-top: 5px;
}
@media only screen and (max-width: 806px) {
  .container {
    max-width: 100% !important;
  }
}
</style>
