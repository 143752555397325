<template>
  <span v-if="this.favorite != 'EVEN'">
    <div class="form-check form-check-inline" @change="setBet($event)">
      <input
        class="form-check-input"
        type="radio"
        :name="this.SpreadValues.RowKey"
        id="inlineRadio1"
        :value="favorite"
      />
      <label class="form-check-label" for="inlineRadio1">{{
        this.favorite
      }}</label>
    </div>
    <div class="form-check form-check-inline" @change="setBet($event)">
      <input
        class="form-check-input"
        type="radio"
        :name="this.SpreadValues.RowKey"
        id="inlineRadio2"
        :value="underdog"
      />
      <label class="form-check-label" for="inlineRadio2">{{
        this.underdog
      }}</label>
    </div>
  </span>
  <span v-else>
    <div class="form-check form-check-inline" @change="setBet($event)">
      <input
        class="form-check-input"
        type="radio"
        :name="this.SpreadValues.RowKey"
        id="inlineRadio1"
        :value="this.SpreadValues.HomeTeamShort + ' +0'"
      />
      <label class="form-check-label" for="inlineRadio1">{{
        this.SpreadValues.HomeTeamShort
      }}</label>
    </div>
    <div class="form-check form-check-inline" @change="setBet($event)">
      <input
        class="form-check-input"
        type="radio"
        :name="this.SpreadValues.RowKey"
        id="inlineRadio2"
        :value="this.SpreadValues.AwayTeamShort + ' +0'"
      />
      <label class="form-check-label" for="inlineRadio2">{{
        this.SpreadValues.AwayTeamShort
      }}</label>
    </div>
  </span>
  <br />
  <span>
    <div class="form-check form-check-inline" @change="setBet($event)">
      <input
        class="form-check-input"
        type="radio"
        :name="this.SpreadValues.RowKey"
        id="inlineRadio3"
        :value="'Over ' + this.SpreadValues.OverUnder"
      />
      <label class="form-check-label" for="inlineRadio3"
        >Over {{ this.SpreadValues.OverUnder }}</label
      >
    </div>
  </span>
  <span>
    <div class="form-check form-check-inline" @change="setBet($event)">
      <input
        class="form-check-input"
        type="radio"
        :name="this.SpreadValues.RowKey"
        id="inlineRadio4"
        :value="'Under ' + this.SpreadValues.OverUnder"
      />
      <label class="form-check-label" for="inlineRadio3"
        >Under {{ this.SpreadValues.OverUnder }}</label
      >
    </div>
  </span>
  <br />
  <span>
    <div class="form-check form-check-inline" @change="setBet($event)">
      <input
        class="form-check-input"
        type="radio"
        :name="this.SpreadValues.RowKey"
        id="inlineRadio5"
        :value="this.SpreadValues.HomeTeamShort + ' ML'"
      />
      <label class="form-check-label" for="inlineRadio3"
        >{{ this.SpreadValues.HomeTeamShort }}
        {{
          this.SpreadValues.HomeMoneyLine > 0
            ? "+" + this.SpreadValues.HomeMoneyLine
            : this.SpreadValues.HomeMoneyLine
        }}</label
      >
    </div>
  </span>
  <span>
    <div class="form-check form-check-inline" @change="setBet($event)">
      <input
        class="form-check-input"
        type="radio"
        :name="this.SpreadValues.RowKey"
        id="inlineRadio6"
        :value="this.SpreadValues.AwayTeamShort + ' ML'"
      />
      <label class="form-check-label" for="inlineRadio3"
        >{{ this.SpreadValues.AwayTeamShort }}
        {{
          this.SpreadValues.AwayMoneyLine > 0
            ? "+" + this.SpreadValues.AwayMoneyLine
            : this.SpreadValues.AwayMoneyLine
        }}</label
      >
    </div>
  </span>
  <div class="container">
    <div class="row">
      <div class="input-group mb-3 col align-self-center">
        <div class="input-group-prepend">
          <span class="input-group-text">$</span>
        </div>
        <input
          type="number"
          min="0"
          inputmode="numeric"
          pattern="[0-9]*"
          class="form-control"
          aria-label="Amount (to the nearest dollar)"
          v-model="bidAmount"
        />
      </div>
    </div>
    <!-- <button type="button" :class="canTheyMakeThisBet()"   @click="submitBet">Submit</button> -->
    <button type="button" :class="canTheyMakeThisBet()" @click="OpenCloseFun">
      Submit
    </button>
  </div>
  <!--Modal-->
  <div
    v-if="OpenClose"
    class="modal fade show"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-modal="true"
    role="dialog"
    style="display: block"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Confirm?</h4>
          <button
            type="button"
            @click="OpenCloseFun()"
            class="btn-close"
          ></button>
        </div>
        <div class="modal-body">
          <h4>{{ this.selectedBet }}</h4>
          <h4>${{ this.bidAmount }} to win ${{ calculatePayout() }}</h4>
          <hr />
          <h4>
            Potential Payout: $<strong>{{ payoutValue() }}</strong>
          </h4>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="submitBet"
            :class="'btn btn-outline-success'"
          >
            Submit
          </button>
          <button
            type="button"
            @click="OpenCloseFun()"
            :class="'btn btn-outline-danger'"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["SpreadValues", "userData", "budget", "league"],
  emits: ["betWasPlaced"],
  data() {
    return {
      playerDataValue: [],
      favorite: this.SpreadValues.Favorite,
      underdog: this.getUnderDog(),
      whoIsTheFavorite: null,
      whoIsTheUnderdog: null,
      selectedBet: null,
      bidAmount: null,
      betWasPlaced: false,
      OpenClose: false,
      visible: Boolean,
      variant: String,
      toWin: null,
    };
  },
  methods: {
    getUnderDog() {
      var tempSpreadValues = this.SpreadValues;
      var favTeam = tempSpreadValues.Favorite.split(" ")[0];
      var line = parseFloat(tempSpreadValues.Favorite.split(" ")[1]);
      this.favorite = tempSpreadValues.Favorite;
      if (favTeam == tempSpreadValues.HomeTeamShort) {
        this.whoIsTheFavorite = tempSpreadValues.HomeFavorite;
        this.whoIsTheUnderdog = tempSpreadValues.AwayUnderdog;
        return tempSpreadValues.AwayTeamShort + " +" + line * -1;
      } else {
        this.whoIsTheFavorite = tempSpreadValues.AwayFavorite;
        this.whoIsTheUnderdog = tempSpreadValues.HomeUnderdog;
        return tempSpreadValues.HomeTeamShort + " +" + line * -1;
      }
    },
    setBet(passedBet) {
      this.selectedBet = passedBet.target.value;
    },
    submitBet() {
      const valuesToPass = {
        userName: this.userData.name,
        userEmail: this.userData.email,
        userId: this.userData.sub,
        BetPlaced: this.selectedBet,
        BetAmount: String(this.bidAmount),
        ClosedDate: String(this.SpreadValues.StartTimeShort),
        //week: this.SpreadValues.PartitionKey,
        league: this.league,
        toWin: this.toWin,
        gameId: this.SpreadValues.RowKey,
        awayTeam: this.SpreadValues.AwayTeamName,
        homeTeam: this.SpreadValues.HomeTeamName,
      };
      this.$emit("betWasPlaced", this.bidAmount);
      this.axios
        .post(
          "https://fantasy-football-bets.vercel.app/api/placeBet",
          valuesToPass
        )
        .then((this.betWasPlaced = true))
        .then((this.bidAmount = null))
        .then(this.OpenCloseFun);
    },
    canTheyMakeThisBet() {
      return this.bidAmount <= this.budget &&
        this.selectedBet != null &&
        /^(?!0\.00$)([1-9]\d*|0)(\.\d{1,2})?$/.test(this.bidAmount)
        ? "btn btn-outline-success"
        : "btn btn-outline-danger disabled";
    },
    OpenCloseFun() {
      this.OpenClose = !this.OpenClose;
    },
    calculatePayout() {
      var isThisML = this.selectedBet.split(" ")[1];
      var betValue = this.selectedBet.split(" ")[0];

      if (isThisML == "ML") {
        if (betValue == this.SpreadValues.HomeTeamShort) {
          if (this.SpreadValues.HomeMoneyLine < 0) {
            this.toWin = (
              (100 / (this.SpreadValues.HomeMoneyLine * -1).toFixed(2)) *
              this.bidAmount
            ).toFixed(2);
          } else {
            this.toWin = (
              (this.SpreadValues.HomeMoneyLine / 100) *
              this.bidAmount
            ).toFixed(2);
          }
        } else {
          if (this.SpreadValues.AwayMoneyLine < 0) {
            this.toWin = (
              (100 / (this.SpreadValues.AwayMoneyLine * -1).toFixed(2)) *
              this.bidAmount
            ).toFixed(2);
          } else {
            this.toWin = (
              (this.SpreadValues.AwayMoneyLine / 100) *
              this.bidAmount
            ).toFixed(2);
          }
        }
      } else {
        this.toWin = ((100 / (110).toFixed(2)) * this.bidAmount).toFixed(2);
      }
      return this.toWin;
      // if(Odds < 0){
      //     this.toWin = (100 / (Odds * -1).toFixed(2) * betAmount).toFixed(2)
      // }else{
      //     this.toWin = ((Odds / 100) * betAmount).toFixed(2)
      // }
    },
    payoutValue() {
      const value = parseFloat(this.toWin) + parseFloat(this.bidAmount);
      return value.toFixed(2);
    },
  },
  watch: {
    visible: function (newVal, oldVal) {
      // watch it
      this.OpenClose = newVal;
      console.log("new" + newVal + "==" + oldVal);
    },
  },
};
</script>
