<template>
  <Navigation />
  <Keeper />
  <Transactions />
</template>

<script>
// @ is an alias to /src
import Keeper from "@/components/Keepers.vue";
import Navigation from "@/components/Navigation.vue";
import Transactions from "@/components/Transactions.vue";

export default {
  name: "Home",
  components: {
    Navigation,
    Keeper,
    Transactions,
  },
};
</script>
