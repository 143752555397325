<template>
  <div v-if="this.passTeamOrOpponent == `opponent`">
    <DataTable
      v-model:expandedRowGroups="expandedRowGroups"
      :value="passDataValues"
      expandableRowGroups
      rowGroupMode="subheader"
      groupRowsBy="NFLTeam"
      stripedRows
      class="player-table"
    >
      <template #groupheader="slotProps">
        <!--<span class="">{{ slotProps.data.NFLTeam }}</span>-->
        <span class="">{{ slotProps.data.NFLTeam }}</span>
      </template>
      <Column
        v-for="col of columns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
      ></Column>
    </DataTable>
  </div>
  <div v-else>
    <DataTable
      v-model:expandedRowGroups="expandedRowGroups"
      :value="passDataValues"
      expandableRowGroups
      rowGroupMode="subheader"
      groupRowsBy="Opponent"
      stripedRows
      class="player-table"
    >
      <template #groupheader="slotProps">
        <!--<span class="">{{ slotProps.data.NFLTeam }}</span>-->
        <span class="">{{ slotProps.data.Opponent }}</span>
      </template>
      <Column
        v-for="col of columns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
      ></Column>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
export default {
  props: ["passDataValues", "passPosition", "passTeamOrOpponent"],
  data() {
    return {
      expandedRowGroups: null,
      columns: null,
    };
  },
  methods: {
    filterOn(data) {
      console.log(data);
      var test = this.passTeamOrOpponent == "opponent" ? "NFLTeam" : "Opponent";
      console.log(test);
      return test;
    },
    propReturn(data) {
      return this.passTeamOrOpponent == "opponent"
        ? data.data.NFLTeam
        : data.data.Opponent;
    },
  },
  components: {
    DataTable,
    Column,
    ColumnGroup,
    Row,
  },
  watch: {
    passPosition() {
      switch (this.passPosition) {
        case "RB":
          this.columns = null;
          this.columns = [
            { field: "PartitionKey", header: "Week" },
            { field: "PlayerName", header: "Player Name" },
            { field: "NFLTeam", header: "NFL Team" },
            { field: "Projected", header: "Projected Points" },
            { field: "Actual", header: "Actual Points" },
            { field: "RushAtt", header: "Rush Attempts" },
            { field: "RushYards", header: "Rush Yards" },
            { field: "RushAvg", header: "Rush Average" },
            { field: "RushTD", header: "Rush TD" },
            { field: "RushLng", header: "Rush Long" },
            { field: "Rec", header: "Receptions" },
            { field: "RecTgts", header: "Targets" },
            { field: "RecYards", header: "Reception Yards" },
            { field: "RecTD", header: "Reception TD" },
            { field: "FumLost", header: "Fumbles Lost" },
          ];
          break;
        case "QB":
          this.columns = [
            { field: "PartitionKey", header: "Week" },
            { field: "PlayerName", header: "Player Name" },
            { field: "NFLTeam", header: "NFL Team" },
            { field: "Projected", header: "Projected Points" },
            { field: "Actual", header: "Actual Points" },
            { field: "Completions", header: "Completions" },
            { field: "Attempts", header: "Attempts" },
            { field: "Yards", header: "Yards" },
            { field: "CompletionPerc", header: "Completion%" },
            { field: "AverageYards", header: "Average" },
            { field: "PassTD", header: "Pass TD" },
            { field: "Interceptions", header: "Interceptions" },
            { field: "PassLong", header: "Long" },
            { field: "Sack", header: "Sack" },
            { field: "Rating", header: "Passer Rating" },
            { field: "QBR", header: "QBR" },
            { field: "RushAtt", header: "Rush Attempts" },
            { field: "RushYards", header: "Rush Yards" },
            { field: "RushTD", header: "Rush TD" },
          ];
          break;
        case "TE":
          this.columns = [
            { field: "PartitionKey", header: "Week" },
            { field: "PlayerName", header: "Player Name" },
            { field: "NFLTeam", header: "NFL Team" },
            { field: "Projected", header: "Projected Points" },
            { field: "Actual", header: "Actual Points" },
            { field: "Rec", header: "Receptions" },
            { field: "RecTgts", header: "Targets" },
            { field: "RecYards", header: "Reception Yards" },
            { field: "RecAvg", header: "Reception Average" },
            { field: "RecTD", header: "Reception TD" },
            { field: "RecLng", header: "Longest Reception" },
            { field: "RushAtt", header: "Rush Attempts" },
            { field: "RushYards", header: "Rush Yards" },
            { field: "RushTD", header: "Rush TD" },
            { field: "FumLost", header: "Fumbles Lost" },
          ];
          break;
        case "WR":
          this.columns = [
            { field: "PartitionKey", header: "Week" },
            { field: "PlayerName", header: "Player Name" },
            { field: "NFLTeam", header: "NFL Team" },
            { field: "Projected", header: "Projected Points" },
            { field: "Actual", header: "Actual Points" },
            { field: "Rec", header: "Receptions" },
            { field: "RecTgts", header: "Targets" },
            { field: "RecYards", header: "Reception Yards" },
            { field: "RecAvg", header: "Reception Average" },
            { field: "RecTD", header: "Reception TD" },
            { field: "RecLng", header: "Longest Reception" },
            { field: "RushAtt", header: "Rush Attempts" },
            { field: "RushYards", header: "Rush Yards" },
            { field: "RushTD", header: "Rush TD" },
            { field: "FumLost", header: "Fumbles Lost" },
          ];
          break;
        case "D":
          this.columns = [
            { field: "PartitionKey", header: "Week" },
            { field: "PlayerName", header: "Player Name" },
            { field: "NFLTeam", header: "NFL Team" },
            { field: "Projected", header: "Projected Points" },
            { field: "Actual", header: "Actual Points" },
          ];
          break;
        case "K":
          this.columns = [
            { field: "PartitionKey", header: "Week" },
            { field: "PlayerName", header: "Player Name" },
            { field: "NFLTeam", header: "NFL Team" },
            { field: "Projected", header: "Projected Points" },
            { field: "Actual", header: "Actual Points" },
            { field: "FGMUnder20", header: "Under 20" },
            { field: "FGM20to30", header: "20 to 30" },
            { field: "FGM30to40", header: "30 to 40" },
            { field: "FGM40to50", header: "40 to 50" },
            { field: "FGM50Plus", header: "50+" },
            { field: "FieldGoalsMade", header: "Field Goals Made" },
            { field: "TotalKickingPoints", header: "EXP Made" },
          ];
          break;
        default:
          console.log("None Selected");
      }
    },
  },
};
</script>

<style>
.p-datatable {
  font-size: small;
  margin-top: 25px;
  max-width: 98%;
  margin-left: 1%;
}
.border-right {
  border-right: 3px solid #dee2e6 !important;
}
.table-responsive {
  max-height: 300px;
}
.header {
  position: sticky;
  top: 0px;
}
td {
  vertical-align: middle;
}
.p-datatable-table {
  width: 100%;
}
@media (max-width: 660px) {
  .p-datatable .p-datatable-thead > tr > :nth-child(n + 5) {
    display: none !important;
  }
  .p-datatable td:nth-child(n + 5) {
    display: none !important;
  }
  .p-datatable-table {
    all: unset;
  }
  .table-responsive {
    max-height: 300px;
  }
  .header {
    position: sticky;
    top: 0;
  }
}
</style>
