<template>
<div class="score-container">
  <div class="score-container-value">
    <table class="table table-hover">
        <thead>
            <tr>
                <th>Week</th>
                <th>Opponent</th>
                <th>Final Score</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="team in teamRecord" :key="team.RowKey">
              <td>{{team.PartitionKey}}</td>
              <td style="width:500px">{{team.RowKey != this.passTeam? team.RowKey : team.LoserTie}}</td>
              <td :class="winLossTie(team)">{{team.RowKey != this.passTeam? team.PtsL +'-'+ team.PtsW : team.PtsW + '-' + team.PtsL }}</td>
            </tr>
        </tbody>
    </table>    
  </div>
</div>    
</template>

<script>
export default {
  props: ['passTeam'],
  data() {
    return {
      teamRecord: [],
    }
  },
  watch: {
    passTeam() {
      this.teamRecord = []
      this.getRecord()
    }
  },
  methods: {
    getRecord(){
      return this.axios
        .get("https://fantasy-football-backend-nfl-data.vercel.app/api/nflRecords?team="+this.passTeam)
        .then(response => (this.teamRecord = response.data));
    },
    winLossTie(teamObject){
      //console.log('winner ' + teamObject.RowKey + ' pass team ' + this.passTeam)
      //console.log('winner points ' + teamObject.PtsW + ' loser points  ' + teamObject.PtsL)
      if(teamObject.RowKey == this.passTeam && parseInt(teamObject.PtsW) > parseInt(teamObject.PtsL) ){
        return 'win'
      } else if(teamObject.RowKey != this.passTeam && parseInt(teamObject.PtsW) > parseInt(teamObject.PtsL) ){
        return 'loss'
      } else{
        return 'tie'
      }
    }
  },
  // computed: {
  //   winLossTie(teamObject){
  //     if(teamObject.RowKey == this.passTeam && teamObject.PtsW > teamObject.PtsL ){
  //       return win
  //     }
  //   }
  // }    
}
</script> 

<style>
.win {
  background-color: white; /* For browsers that do not support gradients */
  background-image: linear-gradient(to right, white , green);
}
.loss {
  background-color: white; /* For browsers that do not support gradients */
  background-image: linear-gradient(to right, white , red);
}
.tie {
  background-color: white; /* For browsers that do not support gradients */
  background-image: linear-gradient(to right, white , grey);
}
.score-container {
  height: 480px;
}
.score-container-value{
    height: 479px;
    overflow: auto;
}
</style>