<template>
  <div class="container" v-if="this.betterData != 'undefined'">
    <div class="row">
      <h2 class="col balance">
        Balance: <br />
        ${{ this.betterData.Budget }}
      </h2>
      <h2 class="col week" v-if="league == 'NFL'">Week {{ week }}</h2>
      <h2 class="col week" v-else>NCAAF Week 0</h2>
      <h2 class="col record">
        Record: <br />{{ trimRecord(this.betterData.BetsWon) }}-{{
          trimRecord(this.betterData.BetsLost)
        }}-{{ trimRecord(this.betterData.BetsPush) }}
      </h2>
    </div>
  </div>
</template>
<script>
export default {
  props: ["week", "userData", "league"],
  expose: ["updateValue"],
  data() {
    return {
      betterData: {
        BetsLost: "0",
        BetsPush: "0",
        BetsWon: "0",
        Budget: "00.00",
      },
      week: 0,
    };
  },
  mounted() {
    this.getData();
    this.getWeek();
  },

  methods: {
    getData() {
      this.axios
        .get(
          "https://fantasy-football-bets.vercel.app/api/getInfo?id=" +
            this.userData.sub
        )
        .then(
          (response) => (
            (this.betterData = response.data),
            this.$emit("betterData", this.betterData)
          )
        );
    },
    getWeek() {
      this.axios
        .get("https://fantasy-football-backend.vercel.app/api/currentweek")
        .then((response) => (this.week = response.data));
    },
    updateValue() {
      this.axios
        .get(
          "https://fantasy-football-bets.vercel.app/api/getInfo?id=" +
            this.userData.sub
        )
        .then(
          (response) => (
            (this.betterData = response.data),
            this.$emit("betterData", this.betterData)
          )
        );
    },
    trimRecord(val) {
      return String(val).split(".")[0];
    },
  },
};
</script>
