<template>
  <div class="container">
    <h1>Privacy Policy for TheFamilyOrFantasy</h1>
    <p>Last Updated: 10/26/2023</p>

    <p>
      Troy Kauffman, values your privacy. This Privacy Policy is designed to
      inform users of our TheFamilyOrFantasy about our practices concerning data
      collection, use, and sharing.
    </p>

    <h2>1. No Data Collection</h2>
    <p>
      We designed the App with your privacy in mind. The App does
      <strong>not</strong> collect, store, process, or transmit any personal or
      sensitive user data. Any data you input into the App remains solely on
      your device unless you choose to share it.
    </p>

    <h2>2. Third-Party Services</h2>
    <p>
      The App may contain links to third-party websites or services. These
      third-party services are not operated by us, and we encourage you to
      review the privacy policies of every third-party service you visit or use.
    </p>

    <h2>3. Changes to this Privacy Policy</h2>
    <p>
      While we do not anticipate changes to this policy given the nature of our
      App, we may update this Privacy Policy from time to time. If we make any
      changes, we will notify users through appropriate channels, which may
      include posting a notice within the App or on our official website.
    </p>

    <h2>4. Contact Us</h2>
    <p>
      If you have any questions or concerns about this Privacy Policy or our
      privacy practices in general, please contact us at
      <a href="mailto:[troykauffman1@gmail.com]">[Troy Kauffman]</a>.
    </p>
  </div>
</template>
