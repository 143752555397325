<template>
  <Navigation />
  <Streams />
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import Streams from "../components/Streams.vue";

export default {
  name: "Home",
  components: {
    Navigation,
    Streams,
  },
};
</script>
