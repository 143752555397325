<template>
  <div class="container">
    <DataTable
      v-model:expandedRows="expandedRows"
      :value="teamData"
      stripedRows
      columnResizeMode="fit"
      sortField="TotalPointsScored"
      :sortOrder="-1"
      dataKey="RowKey"
      @rowExpand="onRowExpand"
      @rowCollapse="onRowCollapse"
      :loading="loading"
    >
      <Column expander style="width: 5rem" />
      <Column field="RowKey" sortable header="Team"></Column>
      <Column
        field="TotalPointsScored"
        :sortable="true"
        header="Total Points"
      ></Column>
      <Column field="PercQB" sortable header="%QB"
        ><template #body="{ data }">
          {{ changeToPercent(data.PercQB) }}
        </template></Column
      >
      <Column field="PercRB" sortable header="%RB"
        ><template #body="slotProps">
          {{ changeToPercent(slotProps.data.PercRB) }}
        </template></Column
      >
      <Column field="PercTE" sortable header="%TE"
        ><template #body="slotProps">
          {{ changeToPercent(slotProps.data.PercTE) }}
        </template></Column
      >
      <Column field="PercWR" sortable header="%WR">
        <template #body="slotProps">
          {{ changeToPercent(slotProps.data.PercWR) }}
        </template></Column
      >
      <template #expansion="slotProps">
        <div>
          <h5>Breakdown for {{ slotProps.data.RowKey }}</h5>
          <DataTable :value="slotProps.data.Actual">
            <Column field="ActualQB" header="QB Points"></Column>
            <Column field="ActualRB" header="RB Points"></Column>
            <Column field="ActualTE" header="TE Points"></Column>
            <Column field="ActualWR" header="WR Points"></Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
    <Toast />
  </div>
</template>
<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Toast from "primevue/toast";
export default {
  data() {
    return {
      teamData: [],
      columns: null,
      expandedRows: [],
    };
  },
  mounted() {
    this.getData();
  },
  created() {
    this.columns = [
      { field: "RowKey", header: "Name" },
      { field: "TotalPointsScored", header: "Points Scored" },
      { field: "PercQB", header: "PercQB" },
      { field: "PercRB", header: "PercRB" },
      { field: "PercTE", header: "PercTE" },
      { field: "PercWR", header: "PercWR" },
    ];
  },
  methods: {
    getData() {
      this.axios
        .get(
          "https://fantasy-football-backend-nfl-data.vercel.app/api/nflTeamPoints"
        )
        .then((response) => (this.teamData = response.data));
    },
    changeToPercent(val) {
      //console.log(val);
      return (val * 100).toFixed(2) + "%";
    },
    expandAll() {
      this.expandedRows = this.teamData.filter((p) => p.RowKey);
    },
    collapseAll() {
      this.expandedRows = null;
    },
    onRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Expanded TeamData",
        detail: event.data.RowKey,
        life: 1000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Collapsed TamData",
        detail: event.data.RowKey,
        life: 1000,
      });
    },
  },
  components: {
    DataTable,
    Column,
    ColumnGroup,
    Row,
    Toast,
  },
};
</script>
<style>
.p-datatable-table {
  table-layout: fixed;
}
</style>
