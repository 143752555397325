<template>
    <Navigation />
    <Projections />
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'
import Projections from '@/components/Projections.vue';


export default {
  name: 'Home',
  components: {
    Navigation,
    Projections
}
}
</script>