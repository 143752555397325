import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";
import Home from "../views/Home.vue";
import Roster from "../views/Roster.vue";
import Winner from "../views/Winner.vue";
import Projections from "../views/Projections.vue";
import PickEm from "../views/PickEm.vue";
import Slips from "../views/Slips.vue";
import NFLTotals from "../views/NFLTotals.vue";
import Stream from "../views/Streams.vue";
import Privacy from "../views/Privacy.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    //beforeEnter: authGuard,
  },
  {
    path: "/roster",
    name: "Roster",
    component: Roster,
    //beforeEnter: authGuard,
  },
  {
    path: "/winners",
    name: "Winners",
    component: Winner,
    //beforeEnter: authGuard,
  },
  {
    path: "/projections",
    name: "Projections",
    component: Projections,
    //beforeEnter: authGuard,
  },
  {
    path: "/NFLTotals",
    name: "NFL Totals",
    component: NFLTotals,
    beforeEnter: authGuard,
  },
  {
    path: "/Streams",
    name: "Stream",
    component: Stream,
  },
  {
    path: "/Privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/pickem",
    name: "PickEm",
    component: PickEm,
    beforeEnter: authGuard,
  },
  {
    path: "/pickem/slips",
    name: "Slips",
    component: Slips,
    beforeEnter: authGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
