<template>
    <Navigation />
    <Roster />
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'
import Roster from '@/components/Roster.vue'


export default {
  name: 'Home',
  components: {
    Navigation,
    Roster
  }
}
</script>