<template>
  <div v-if="isAuthenticated && week">
    <BetterData
      @betterData="returnBetterData"
      :league="selectedSport"
      :week="week"
      :userData="user"
      ref="betinfo"
    ></BetterData>
  </div>
  <!--<div
    class="btn-group"
    role="group"
    aria-label="Basic radio toggle button group"
  >
    <input
      type="radio"
      class="btn-check"
      name="btnradio"
      id="NFLRadio"
      autocomplete="off"
      checked
    />
    <label
      class="btn btn-outline-primary"
      id="NFLRadio"
      for="NFLRadio"
      @click="this.selectedSport = 'NFL'"
      >NFL</label
    >

    <input
      type="radio"
      class="btn-check"
      name="btnradio"
      id="NCAAFRadio"
      autocomplete="off"
    />
    <label
      class="btn btn-outline-primary"
      for="NCAAFRadio"
      @click="this.selectedSport = 'NCAAF'"
      >NCAAF</label
    >
  </div>-->
  <!-- <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
    <<input type="radio" class="btn-check" name="btnradio" id="NBARadio" autocomplete="off" checked>
    <label class="btn btn-outline-primary" id="NBARadio" for="NBARadio" @click="this.selectedSport = 'NBA'">NBA</label>

    <input type="radio" class="btn-check" name="btnradio" id="NCAAMBRadio" autocomplete="off" checked>
    <label class="btn btn-outline-primary" for="NCAAMBRadio" @click="this.selectedSport = 'NCAAMB'" style="display: none;">NCAAMB</label>
  </div> -->
  <div class="row" v-if="isAuthenticated && this.selectedSport == 'NFL'">
    <KeepAlive>
      <div
        :class="convertTime(games.FilterDate)"
        v-for="games in SpreadData"
        :key="games.RowKey"
      >
        <div class="card shadow">
          <div class="card-body">
            <h5 class="card-title">
              {{ games.AwayTeamName }}
              <img class="teamlogo" :src="teamLogo(games.AwayTeamShort)" />
              <br />
              @ {{ games.HomeTeamName }}
              <img class="teamlogo" :src="teamLogo(games.HomeTeamShort)" />
            </h5>
            <p class="card-text">
              {{ games.StartTimeDetail }}
            </p>
            <p class="card-text">
              {{
                games.Indoor == "True"
                  ? "Indoors"
                  : games.WeatherDisplay +
                    ". Game Temp " +
                    games.WeatherTemp +
                    " &#8457;"
              }}
            </p>
            <p class="card-text">
              {{ games.Favorite }} | O/U {{ games.OverUnder }}
            </p>
            <SelectBets
              @betWasPlaced="aBetWasPlaced"
              :league="selectedSport"
              :SpreadValues="games"
              :userData="user"
              :budget="betterInformation.Budget"
            ></SelectBets>
          </div>
        </div>
      </div>
    </KeepAlive>
  </div>
  <div class="row" v-if="isAuthenticated && this.selectedSport != 'NFL'">
    <KeepAlive>
      <div
        :class="convertTime(games.FilterDate)"
        v-for="games in SpreadData"
        :key="games.RowKey"
      >
        <div class="card shadow">
          <div class="card-body">
            <h5 class="card-title">
              <span class="rank" v-if="games.AwaySeed">{{
                games.AwaySeed
              }}</span>
              {{ games.AwayTeamName }}
              <img class="teamlogo" :src="games.AwayLogo" /> <br />
              @
              <span class="rank" v-if="games.HomeSeed">{{
                games.HomeSeed
              }}</span>
              {{ games.HomeTeamName }}
              <img class="teamlogo" :src="games.HomeLogo" />
            </h5>
            <p class="card-text">
              {{ games.StartTimeDetail }}
            </p>
            <h5 class="card-text">
              {{
                games.PartitionKey.split("Men's Basketball Championship -")[1]
              }}
            </h5>
            <p class="card-text">
              {{ games.Favorite }} | O/U {{ games.OverUnder }}
            </p>
            <SelectBets
              @betWasPlaced="aBetWasPlaced"
              :league="selectedSport"
              :SpreadValues="games"
              :userData="user"
              :budget="betterInformation.Budget"
            ></SelectBets>
          </div>
        </div>
      </div>
    </KeepAlive>
  </div>
</template>
<script>
//import {ref} from 'vue';
import { useAuth0 } from "@auth0/auth0-vue";
import SelectBets from "./PickEm Components/SelectBets.vue";
import BetterData from "./PickEm Components/BetterData.vue";
export default {
  data() {
    const { isAuthenticated, user } = useAuth0();
    return {
      SpreadData: [],
      isAuthenticated,
      user,
      week: null,
      betterInformation: {},
      betPlaced: false,
      selectedSport: "NFL",
    };
  },
  mounted() {
    this.getData();
    //this.checkIfGamesEnded();
  },

  methods: {
    getData() {
      this.axios
        .get("https://fantasy-football-backend.vercel.app/api/currentweek")
        .then((response) => (this.week = response.data));
    },
    checkIfGamesEnded() {
      this.axios.get(
        "https://ffazurefunctions.azurewebsites.net/api/FFUpdateSlips?name=" +
          String(this.user.name) +
          ""
      );
    },
    returnBetterData(val) {
      this.betterInformation = val;
    },
    aBetWasPlaced(val) {
      var tempData = this.betterInformation;
      tempData.Budget = parseFloat(tempData.Budget) - val;
      this.betterInformation.Budget = tempData.Budget;
    },
    convertTime(val) {
      var gameDateTime = val;
      var userDateTime = new Date();

      if (Date.parse(gameDateTime) > Date.parse(userDateTime)) {
        //console.log("Game hasn't been played yet")
        return "col-sm-4 justify-content-center align-items-stretch";
      } else {
        //console.log("game passed")
        return "hideThis";
      }
    },
    teamLogo(game) {
      if (this.selectedSport == "NFL")
        return (
          "https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/" +
          game +
          ".png&scale=crop&cquality=40&location=origin&w=80&h=80"
        );
    },
  },
  watch: {
    week() {
      this.axios
        .get(
          "https://fantasy-football-bets.vercel.app/api/getSpreads?week=" +
            this.week +
            "&selectedRadio=" +
            this.selectedSport
        )
        .then((response) => (this.SpreadData = response.data));
    },
    selectedSport() {
      if (this.selectedSport == "NFL") {
        //this.SpreadData = []
        this.axios
          .get(
            "https://fantasy-football-bets.vercel.app/api/getSpreads?week=" +
              this.week +
              "&selectedRadio=" +
              this.selectedSport
          )
          .then((response) => (this.SpreadData = response.data));
      } else {
        //this.SpreadData = []
        this.axios
          .get(
            "https://fantasy-football-bets.vercel.app/api/getSpreads?selectedRadio=" +
              this.selectedSport
          )
          .then((response) => (this.SpreadData = response.data));
      }
    },
  },
  computed: {},
  components: { SelectBets, BetterData },
};
</script>
<style>
.row {
  margin: 10px;
}
.card {
  margin: 10px;
}
.hideThis {
  display: none;
}
.teamlogo {
  height: 33px;
}

#NFLRadio {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.rank {
  font-size: 15px;
}
@media only screen and (max-width: 390px) {
  .card {
    width: 100%;
    margin: 0px;
    margin-top: 10px;
  }
  .row {
    margin: 0px;
  }
}
</style>
